import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import ReactDrawer from 'react-drawer';

//Import Components
import RightSidebar from "../../components/Layout/RightSidebar";
import Section from "./section";
// import Partners from "./Partners";
import Feature from "../../components/Shared/Feature";
import FeatureDescription from "./FeatureDescription";
import AIAgents from "./AIAgents";
// import CTA from "./cta";
import Pricings from "./pricings";
import Trial from "../../components/Shared/Trial";
import { featureArray, facilities, testimonials } from "../../common/data";
// import classic02 from "../../assets/images/saas/classic02.png";
import classic from "../../assets/images/property-management/classic.png";
import myclassic1 from "../../assets/images/property-management/myclassic1.png";
import myclassic2 from "../../assets/images/property-management/myclassic2.png";
import homeImage from "../../assets/images/app/zenstrin/1.png";
// import Popup from "../../components/Layout/popup";

//Import Images

const IndexclassNameicSaas = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
    });
    window.addEventListener("scroll", scrollNavigation, true);
  })

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      document.querySelector(".settingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      document.querySelector(".settingbtn")?.classList.remove("btn-soft-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      document.querySelector(".settingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      {/* render section */}
      <Section onDrawerOpen={toggleDrawer} isDrawerOpen={isDrawerOpen} />

      {/* render partner */}
      {/* <Partners /> */}
      
      <section className="section overflow-hidden">
        <AIAgents />
        <Container className="pb-5 mb-md-5">
          {/* feature box */}
          <Feature featureArray={featureArray} isCenter={true} />
        </Container>

        {/* Render Feature Description */}
        <FeatureDescription
          image1={classic}
          image2={homeImage}
          image3={classic}
          testimonials={testimonials}
          facilities={facilities}
          onDrawerOpen={toggleDrawer} isDrawerOpen={isDrawerOpen} />
        {/* <CTA /> */}
        {/* <Pricings /> */}
        <Trial onDrawerOpen={toggleDrawer} isDrawerOpen={isDrawerOpen} />
      </section>

      {/* <Popup/> */}

      {/* <RequestDemo toggleModal={toggleModal} isOpen={isModalOpen}/> */}
      <ReactDrawer
        open={isDrawerOpen}
        position={"right"}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  );
}

export default IndexclassNameicSaas;
