import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

// import images
import landing from "../../../assets/images/property-management/data-sources.png";
import chatZEN from "../../../assets/images/app/zenstrin/8.png";
import compliance from "../../../assets/images/property-management/compliance.png";

export default class Team extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6} className="order-1 order-md-2">
              <img
                src={chatZEN}
                className="img-fluid rounded-md "
                alt=""
              />
            </Col>

            <Col
              lg={7}
              md={6}
              className="order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
            >
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">
                  Chatbot<br /> Tenant Assistant
                </h4>
                <p className="text-muted mb-0 para-desc">
                  Save time by allowing our chatbot to answer everyday questions your tenants may have.
                  {" "}
                  {/* <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "} */}
                </p>

                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-clock" style={{ color: "#f7961c" }}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Answer questions from tenants 24/7
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-file" style={{ color: "#f7961c" }}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Add information about your company and services and enable the AI to tailor its responses to your business
                    </Link>
                  </div>
                </div>

              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-100">

            <Col lg={5} md={6} className="order-1 order-md-1">
              <img
                src={compliance}
                className="img-fluid rounded-md shadow-lg"
                alt=""
              />
            </Col>

            <Col
              lg={7}
              md={6}
              className="order-2 order-md-2 mt-4 pt-2 mt-sm-0 pt-sm-0"
            >
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">
                  Compliance<br /> AI Agent
                </h4>
                <p className="text-muted mb-0 para-desc">
                  Avoid costly fines and penalties by staying compliant with local laws and regulations. Our AI can help you audit your properties on a regular basis and provide you with a list of items that need to be addressed.
                  {" "}
                  {/* <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "} */}
                </p>

                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-bell" style={{ color: "#f7961c" }}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Receieve daily, weekly, monthly, and quarterly compliance reports
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-file" style={{ color: "#f7961c" }}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Our AI can read all your documents and contracts to ensure it is compliant with local laws, regulations abd your company's policies.
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-grid" style={{ color: "#f7961c" }}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Flag any potential compliance issues in documents and contracts immediately they are uploaded to the system, so you can address them before they become a problem.
                    </Link>
                  </div>
                </div>

              </div>
            </Col>
          </Row>

          <Row className="align-items-center mt-100">
            <Col lg={5} md={6} className="order-1 order-md-2">
              <img
                src={landing}
                className="img-fluid rounded-md shadow-lg"
                alt=""
              />
            </Col>

            <Col
              lg={7}
              md={6}
              className="order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
            >
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">
                  Property Manager<br /> AI Assistant
                </h4>
                <p className="text-muted mb-0 para-desc">
                  Our AI can help you manage your properties more efficiently by automating routine tasks and providing you with valuable insights into your business.
                  {" "}
                  {/* <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "} */}
                </p>

                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-wrench" style={{ color: "#f7961c" }}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                    Process maintenance requests faster, create jobs for service providers.
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-chart" style={{ color: "#f7961c" }}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Analyze all your data to provide you with valuable insights into your business and recommendations on actions you can take to improve your operations.
                    </Link>
                  </div>
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
